import jsPDF from "jspdf";
import { receptionPlaces } from "utils/Service";

export const form02 = (dataVehicle, form, place) => {
  const placeName = receptionPlaces().find((r) => r.value === place).text;
  const doc02 = new jsPDF({
    orientation: "p",
    unit: "cm",
    format: [21, 29.7], //medida 08 en pixeles = 20.3 cm x 30.5cm
  });
  const {
    plate_number,
    car: { brand, type, model },
    engine: { engineBrand, engineNumber, chasisBrand, chasisNumber },
  } = dataVehicle;
  doc02.setFont("helvetica");
  doc02.setFontSize(10);
  doc02.text(10, 4.6, plate_number);
  doc02.text(16.5, 24.2, plate_number);
  doc02.text(14, 24.6, brand);
  doc02.text(13.6, 25, type);
  doc02.text(14.1, 25.4, model);
  doc02.text(14.9, 25.8, engineBrand);
  doc02.text(15.2, 26.2, engineNumber);
  doc02.text(15, 26.6, chasisBrand);
  doc02.text(15.1, 27, chasisNumber);
  doc02.save(`${placeName}-formulario-${form}-${plate_number}.pdf`);
};

export default {};
