import jsPDF from "jspdf";
import { receptionPlaces } from "utils/Service";

export const form04 = (dataVehicle, form, place) => {
  const placeName = receptionPlaces().find((r) => r.value === place).text;
  const doc04 = new jsPDF({
    orientation: "p",
    unit: "cm",
    format: [21, 29.7], //medida 08 en pixeles = 20.3 cm x 30.5cm
  });
  const {
    plate_number,
    car: { brand, type, model },
    engine: { engineBrand, engineNumber, chasisBrand, chasisNumber },
  } = dataVehicle;
  doc04.setFont("helvetica");
  doc04.setFontSize(10);
  doc04.text(10, 4.6, plate_number);
  doc04.text(9, 20.4, plate_number);
  doc04.text(6.5, 21.1, brand);
  doc04.text(5.5, 21.7, type);
  doc04.text(6, 22.4, model);
  doc04.text(7, 23, engineBrand);
  doc04.text(7, 23.6, engineNumber);
  doc04.text(6.5, 24.4, chasisBrand);
  doc04.text(7, 25, chasisNumber);
  doc04.save(`${placeName}-formulario-${form}-${plate_number}.pdf`);
};

export default {};
